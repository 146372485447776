import moment from 'moment';

export const tableFilters = {

	data() {
		return {
			loaded: false,
			filters: {
				search:{},
				sort:[{
					field: 'id',
					dir: 'DESC'
				}],
				pivot_mode: {
					enabled: false,
					column: {},
					metric: {
						field: {},
						method: ''
					},
					row: {}
				},
				groups:[],
				columns:[],
				limit:0,
				offset:0
			},
			defaultData: {},
			groups:[],
			columns:[],
			filter_data:{},
			column_structure: {},
			filter_structure:[],
			filter_params: {},
			search_params:{},
			sort:{},
			structure_loaded: false
		}
	},
	filters:{

	},

	mounted(){
		// this.defaultData = JSON.parse(JSON.stringify(this.filters));
		//this.processQuery(this.$route);
	},

	computed:{

	},

  methods:{

		async getStructure(api, type) {
			let response = await api.get(this, api.REPORTS + 'structure/' + type);
			this.filters = response.filters;
			this.filter_structure = response.filter_structure;
			//column structure
			this.column_structure = response.column_structure;

			console.log('getStructure', this.filters);
			this.defaultData = JSON.parse(JSON.stringify(this.filters));
			if(typeof this.setData == 'function') {
				await this.setData();
			}
			// await this.processQuery(this.$route.query);
		},

    setPage(page){

			this.filters.page = page;

			this.filters.offset = +this.filters.page === 0 ? 0 : ((+this.filters.page-1) * this.filters.limit);

			this.buildQuery();
		},

    setFilters(filters){

			this.filters = filters;
			this.buildQuery();

		},

		setSearchParams(search){

			this.filters.search = search;
			// this.filters.offset = 0;
			this.buildQuery();

		},

    setColumns(data){
      this.filters.columns = data.columns ? data.columns: this.filters.columns;
      this.filters.groups =  data.groups ? data.groups : this.filters.groups;

      this.buildQuery();

    },

		resetFilters(){
			this.filters = JSON.parse(JSON.stringify(this.defaultData));
			this.buildQuery();
		},

    slugify(text){
		return text.toString().toLowerCase()
			.replace(/\s+/g, '-')           // Replace spaces with -
			.replace(/[^\w-]+/g, '')       // Remove all non-word chars
			.replace(/-+/g, '-')         // Replace multiple - with single -
			.replace(/^-+/, '')             // Trim - from start of text
			.replace(/-+$/, '');            // Trim - from end of text
	},

    buildQuery(stopRedirect){

			var str = '?';

			for (var key in this.filters.search) {
				if (str != "?") {
					str += "&";
				}
				if( this.filters.search[key] === null){
					str += key + "=";
				} else if( typeof this.filters.search[key].getMonth === 'function'){
					str += key + "=" + moment(this.filters.search[key]).format('YYYY-MM-DDTHH:mm:ssz');
				} else if ( this.filters.search[key] !== null && ( typeof this.filters.search[key] === 'object' || Array.isArray(this.filters.search[key]) )){
					str += key + "=" + encodeURIComponent(JSON.stringify(this.filters.search[key]));
				} else if(this.isValidDate(this.filters.search[key])){
					str += key + "=" + moment(this.filters.search[key], 'YYYY-MM-DDTHH:mm:ssz').local().format('YYYY-MM-DDTHH:mm:ssz');
				} else {
					str += key + "=" + encodeURIComponent(this.filters.search[key]);
				}
			}

			var allCols = [];

			if(this.filters.columns.length){
				allCols = this.filters.columns;
			} else {
				allCols = this.column_structure.filter( c => !c.hide ).map(c => c.key);
			}


      var allGroups = [];

      if(this.filters.groups.length){
        allGroups = this.filters.groups;
      }

			str += "&columns=" +  encodeURIComponent(allCols);
			str += "&groups=" +  encodeURIComponent(allGroups);
			str += '&sort=' + encodeURIComponent(JSON.stringify(this.filters.sort));
			str += '&page=' + this.filters.page;
			// str += '&offset=' + this.filters.offset;
			str += '&limit=' + encodeURIComponent(this.filters.limit);

			if(stopRedirect) return str;

			this.$router.push(this.$route.path + str);

		},

    processQuery(query){

			var filters = this.filters ||  {
				search:{},
				sort:{},
				columns:[],
				groups:[],
				page:1,
				limit: 0,
				offset: 0
			};

			filters.limit = this.getLimit();

			for( var key in query){
				switch(key){
					case 'sort':
						try{
							filters.sort = query.sort ? JSON.parse(decodeURIComponent(query.sort)): filters.sort;
						} catch(err){
							filters.sort = [];
						}

						break;
					case 'columns':
						try{
							filters.columns = query.columns.split(',');
						} catch(err){
							filters.columns= [];
						}
						break;
          case 'groups':
            try{
              filters.groups = query.groups.split(',');
            } catch(err){
              filters.groups = [];
            }
            break;
					case 'limit':
						filters.limit = +query.limit || filters.limit;
						break;
					case 'page':
						filters.page = query.page || filters.page;
						break;
					default:
						if(this.hasJsonStructure(query[key])){
							try{
								filters.search[key] = JSON.parse(query[key]);
							} catch(err){
								filters.search[key] = query[key];
							}
						} else if(this.isValidDate(query[key])){
							filters.search[key] = moment(query[key], 'YYYY-MM-DDTHH:mm:ssz').toDate();
						} else {
							filters.search[key] = query[key];
						}
				}
			}

			this.filters = filters;
			this.search();
			this.$nextTick(() => {
				this.structure_loaded = true;
			})
		},

    getCurrentPath(){
			var currentURL = (document.URL);
			return currentURL.split("?")[1] || '';
		},

    isValidDate(date){
			const regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
			return regExp.test(date);
		},

    hasJsonStructure(str) {
			if (typeof str !== 'string') return false;
			try {
				const result = JSON.parse(str);
				return Object.prototype.toString.call(result) === '[object Object]' || Array.isArray(result);
			} catch (err) {
				return false;
			}
		},

    getLimit(){
			"use strict";

			var tabletop =  document.getElementById('main');

			if(!tabletop) return 25;
			var w = window,
				d = document,
				e = d.documentElement,
				g = d.getElementsByTagName('body')[0],
				// x = w.innerWidth || e.clientWidth || g.clientWidth,
				y = w.innerHeight|| e.clientHeight|| g.clientHeight;

			var distanceFromTop = tabletop.getBoundingClientRect().top + 76 + 56 + 20 ;
			return Math.floor((y - distanceFromTop) / 51)
		},
	},

	watch:{
		'$route' (to) {
			this.processQuery(to.query);
		},
	}
};
